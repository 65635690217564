import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引用全局样式
import '@/assets/css/global.css'
// 导入图标
import './assets/css/iconfont.css'
// 引用axios请求
import axios from 'axios'
// axios.defaults.baseURL = 'https://wcc.vnla.cn'
axios.defaults.baseURL = 'https://wcc.ldydtech.com'
// axios.defaults.baseURL = 'http://192.168.10.73:8004'

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

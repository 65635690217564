import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/verify',
    component: () => import('../views/verify.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/signIn',
    name: 'signIn',
    component: () => import('../views/signIn.vue')
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('../views/forgetPassword.vue')
  },
  {
    path: '/bawangfan',
    name: 'bawangfan',
    component: () => import('../views/bawangfan.vue')
  },
  {
    path: '/SetPassword',
    name: 'SetPassword',
    component: () => import('../views/SetPassword.vue')
  },
  {
    path: '/forgetPass',
    name: 'forgetPass',
    component: () => import('../views/forgetPass.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/agreement.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
